module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#FFFFFF","theme_color":"#000000","display":"minimal-ui","icon":"/opt/build/repo/static/images/icons/logo.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-plugin-netlify-cms-paths","id":"38c6e925-a997-5c02-8e9e-63f605b3f4c7","name":"gatsby-plugin-netlify-cms-paths","version":"1.3.0","pluginOptions":{"plugins":[],"cmsConfig":"/static/admin/config.yml"},"nodeAPIs":["onCreateNode"],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"a48231df-7fb3-550f-b780-60c48ea05012","name":"gatsby-remark-images","version":"3.3.1","pluginOptions":{"plugins":[],"maxWidth":1500},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1500},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
